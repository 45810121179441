import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const MyAgentsLoadable = Loadable({
  loader: () => import('@/sections/MyAgents'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const MyAgentsPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="My Agents" />
      <PrivateProvider>
        <MyAgentsLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default MyAgentsPage;
